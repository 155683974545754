import Preloader from "./Preloader"

const Header = () => {
  return (
    <div>
           {/* Page Preloder */}
  {/* <div id="preloder">
    <div className="loader" />
  </div>  */}
  {/* <Preloader /> */}
    </div>
  )
}

export default Header
